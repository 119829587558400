<template>
  <div v-if="proxy">
    <v-toolbar v-if="!proxy.isWaitingRoomOpen" color="primary" dark>
      <v-toolbar-title
        >Wainting Room for Dr. {{ proxy.fname }} {{ proxy.lname }} is
        closed.</v-toolbar-title
      >
    </v-toolbar>
    <v-toolbar v-else color="primary" dark>
      <v-toolbar-title
        >Joining Room for Dr. {{ proxy.fname }}
        {{ proxy.lname }}</v-toolbar-title
      >
    </v-toolbar>
    <v-dialog
      max-width="500px"
      v-model="ask"
      persistent
      content-class="add-sup-dialog"
    >
      <v-row>
        <v-col cols="12" md="6">
          <v-card
            style="cursor: pointer;"
            @click="startCall()"
            class="fill-height d-flex flex-column justify-center align-center"
          >
            <v-card-text class="text-center">
              <img
                style="max-width: 100%; height: 100px"
                src="@/assets/png/wait.png"
                alt=""
              />
            </v-card-text>
            <v-card-text class="text-center text-md-h6">
              Go to Waiting Room
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            style="cursor: pointer;"
            @click="addSupplemental()"
            class="fill-height d-flex flex-column justify-center align-center"
          >
            <v-card-text class="text-center">
              <img
                style="max-width: 100%;height: 100px"
                src="@/assets/png/add.png"
                alt=""
              />
            </v-card-text>
            <v-card-text class="text-center text-md-h6">
              Add Supplemental Info (Vitals, History, Documents)
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>

    <div>
      <img id="wrBg" v-if="proxy.wrImageURL" :src="proxy.wrImageURL" />
    </div>
    <v-dialog
      max-width="600px"
      v-if="proxy.isWaitingRoomOpen && !currentUser"
      v-model="loginCardDialog"
      persistent
    >
      <RoomLogin :proxy="proxy" />
    </v-dialog>
  </div>
</template>

<script>
import * as fb from '@/firebase'
import PublicLayout from '@/layouts/PublicLayout'
import RoomLogin from '@/patientViews/specialRoutes/waitingRoomLogin'
// import * as moment from 'moment'
export default {
  name: 'Doctor',
  props: ['user'],
  components: {
    RoomLogin
  },
  data() {
    return {
      loginCardDialog: true,
      doctor: null,
      url: '',
      path: '',
      called: false,
      proxy: null,
      ask: false,
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
        this.$emit(`update:layout`, PublicLayout)
      }
    },
    '$route.params.doctorLink': {
      immediate: true,
      async handler() {
        if (this.$route.params.doctorLink) {
          try {
            await fb.db
              .collection('Doctor_Links')
              .where('doctorLink', '==', this.$route.params.doctorLink)
              .get()
              .then(snap => {
                for (const link of snap.docs) {
                  const data = link.data()
                  this.doctor = {
                    id: link.id,
                    ...data
                  }
                }
              })
          } catch (error) {
            console.log(`Doctor Page catch1: ${error}`)
          }
        }
      }
    },
    doctor: async function() {
      if (this.doctor) {
        await this.$bind('proxy', fb.proxiesCollection.doc(this.doctor.id))
      } else {
        this.$router.push(this.$route.query.redirect || { name: '404' })
      }

      if (this.proxy && this.currentUser) {
        this.generateAppointment()
      }
    }
  },
  methods: {
    generateAppointment() {
      try {
        const appointment = fb.db
          .collection('Practices')
          .doc(this.proxy.practiceId)
          .collection('Appointments')
          .doc()

        const appId = appointment.id
        this.path = '?code=' + appId + '&p=' + this.proxy.practiceId

        this.url =
          process.env.VUE_APP_URL +
          'room?code=' +
          appId +
          '&p=' +
          this.proxy.practiceId

        const start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
          new Date()
        )
        const end = fb.db.app.firebase_.firestore.Timestamp.fromDate(new Date())

        appointment.set(
          {
            patientId: this.currentUser.id,
            doctorId: this.proxy.id,
            uniqueRoom: appId,
            url: this.url,
            path: this.path,
            firstTime: true,
            immediate: false,
            adHoc: true,
            isWaiting: false,
            ekoConnected: false,
            recordTime: null,
            startRecEkoFromDoc: false,
            medicalHistory: [],
            medicalHistoryQuestions: null,
            vitals: {
              painscale: null,
              pulse: null,
              pulseox: null,
              resprate: null,
              temperature: null
            },
            isExamComplete: false,
            vitalStamp: null,
            created: fb.db.app.firebase_.firestore.Timestamp.fromDate(
              new Date()
            ),
            startTime: start,
            endTime: end
          },
          { merge: true }
        )
        localStorage.called = 'true'
        // this.startCall()
        this.ask = true
      } catch (error) {
        this.error = error.message
        // console.log(`Doctor Page catch2: ${error}`)
      }
    },
    startCall() {
      localStorage.called = ''
      this.$router.push(this.$route.query.redirect || '/room' + this.path)
    },
    addSupplemental() {
      localStorage.called = ''
      this.$router.push(
        this.$route.query.redirect ||
          '/my-exam' + this.path + '&pid=' + this.currentUser.id
      )
    }
  }
}
</script>

<style scoped>
#wrBg {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}
</style>
