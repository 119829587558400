<template>
  <v-card class="login-card">
    <v-row align="center" justify="center">
      <v-col>
        <div style="flex-grow: 1;">
          <v-form id="form" ref="form" v-model="valid">
            <v-text-field
              v-if="!anon"
              v-model="form.email"
              name="email"
              label="Email"
              type="text"
              :rules="[rules.required]"
              outlined
              rounded
              dense
            />
            <v-text-field
              v-if="!anon"
              id="password"
              v-model="form.password"
              :type="hidePassword ? 'password' : 'text'"
              :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
              name="password"
              label="Password"
              :rules="[rules.required]"
              outlined
              rounded
              dense
              @click:append="hidePassword = !hidePassword"
            />

            <v-text-field
              v-if="anon"
              v-model="form.firstName"
              name="firstName"
              label="First Name"
              type="text"
              :rules="[rules.required]"
              outlined
              rounded
              dense
            />
            <v-text-field
              v-if="anon"
              v-model="form.lastName"
              name="lastName"
              label="Last Name"
              type="text"
              :rules="[rules.required]"
              outlined
              rounded
              dense
            />

            <v-dialog
              ref="menu"
              v-if="anon"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
              max-width="290px"
              @change="getAge()"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="tempBirthday"
                  append-icon="mdi-calendar"
                  label="Birthdate"
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  rounded
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="tempBirthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                label="Birthdate"
                ><v-btn text color="primary" @click="saveBirthday()"
                  >Save</v-btn
                ></v-date-picker
              >
            </v-dialog>

            <v-btn
              v-if="anon"
              color="primary"
              @click="anon = false"
              style="padding: 0 5px;"
              text
            >
              Join with an existing account
            </v-btn>
            <v-btn
              v-else
              color="primary"
              @click="anon = true"
              style="padding: 0 5px;"
              text
            >
              Join as guest
            </v-btn>
            <v-btn
              :block="$mq === 'mobile'"
              color="primary"
              :loading="loading"
              @click="signIn"
              style="float: right;"
              >Join Waiting Room</v-btn
            >
            <br />
            <br />
            <div></div>
            <div style="margin-top: 12px;">
              <ForgotPasswordDialog></ForgotPasswordDialog>
            </div>
            <div>
              <v-btn
                color="primary"
                to="/patientregistration"
                style="padding: 0 5px;"
                text
              >
                <span class="mr-1" style="color: #9E9E9E !important;"
                  >Don't have an account?</span
                >
                Create Account!
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" absolute>
      {{ error }}
      <v-btn color="red" text @click="snackbar = false">Okay</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import * as fb from '@/firebase'
import moment from 'moment'
import ForgotPasswordDialog from '@/components/dialogs/ForgotPasswordDialog'
// import logoUH from '@/assets/logo-white.png'
// import logoVP from '@/assets/logo-white.png'

export default {
  name: 'WaitingRoomLogin',
  components: { ForgotPasswordDialog },
  props: ['proxy'],
  data() {
    return {
      // logoUH: logoUH,
      // logoVP: logoVP,
      // siteMode: process.env.VUE_APP_SITE,
      valid: false,
      loading: false,
      menu: false,
      error: null,
      snackbar: false,
      hidePassword: true,
      tempBirthday: null,
      doctor: null,
      anon: true,
      form: {
        fname: '',
        lname: '',
        email: '',
        password: '',
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: '',
          address: '',
          pharmacy: ''
        }
      },
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  watch: {
    proxy: {
      immediate: true,
      handler() {
        if (this.proxy) {
          this.doctor = this.proxy
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },

  methods: {
    saveBirthday() {
      this.getAge()
      this.menu = false
    },
    getAge() {
      var birthday = this.tempBirthday
      const age = moment().diff(birthday, 'years')
      this.form.medicalcard.age = age
      this.form.medicalcard.birthdate = fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(this.tempBirthday)
      )
    },
    signIn() {
      const users = fb.patientsCollection
      this.$refs.form.validate()
      if (!this.valid) return

      this.loading = true

      if (!this.anon) {
        fb.auth
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then(() => {
            this.loading = false
            this.$refs.form.reset()
            // if (fb.auth.currentUser.displayName === 'proxy') {
            //   this.$router.push(this.$route.query.redirect || '/appointments')
            //   logLogin()
            // } else {
            //   this.$router.push(
            //     this.$route.query.redirect ||
            //       '/' + this.$route.params.doctorLink
            //   )
            // }
          })
          .catch(error => {
            this.error = error.message
            this.loading = false
            this.snackbar = true
          })
      } else {
        if (this.form.firstName && this.form.lastName) {
          //anon login

          fb.auth
            .setPersistence(fb.auth.app.firebase_.auth.Auth.Persistence.SESSION)
            .then(() => {
              fb.auth
                .signInAnonymously()
                .then(cred => {
                  cred.user.updateProfile({
                    displayName: 'patient'
                  })

                  users.doc(cred.user.uid).set({
                    fname: this.form.firstName,
                    lname: this.form.lastName,
                    email: 'anonymous',
                    phoneNumber: 'N/A',
                    receiveTextNotifications: true,
                    role: 'Patient',
                    doctorId: '',
                    medicalcard: {
                      age: this.form.medicalcard.age,
                      address: '',
                      birthdate: this.form.medicalcard.birthdate,
                      gender: '',
                      height: '',
                      pharmacy: '',
                      weight: ''
                    },
                    onboardingComplete: true,
                    primaryDoctor: '',
                    photoURL:
                      'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/avatar.png?alt=media&token=44b5812d-a7ad-4f80-ac34-5386c88bd4a7',
                    createdAt: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                      new Date()
                    )
                  })
                })
                .then(() => {
                  this.loading = false
                  this.$refs.form.reset()

                  // this.$router.push(
                  //   this.$route.query.redirect ||
                  //     '/' + this.$route.params.doctorLink
                  // )
                })
                .catch(error => {
                  this.error = error.message
                  this.loading = false
                  this.snackbar = true
                })
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    clearForm() {
      this.$refs.form.reset()
      Object.keys(this.form).forEach(key => (this.form[key] = null))
    }
  }
}
</script>

<style scoped>
#form {
  margin-top: 0;
}

.container {
  max-width: 737px !important;
}

.login-container {
  max-width: 737px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.login-card {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.v-btn {
  text-transform: capitalize;
}

@media (max-width: 768px) {
}
</style>
